<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
          <template v-slot:body>
            <b-overlay :show="loading">
              <b-row>
                <b-col lg="12" sm="12">
                  <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                    <b-form @submit.prevent="handleSubmit(saveData)" @reset.prevent="reset">
                      <b-row>
                        <b-col lg="6" md="6" sm="12" xs="12">
                            <ValidationProvider name="Country Name" vid="country_id" rules="required|min_value:1">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    label-for="country_id"
                                    slot-scope="{ valid, errors }"
                                    >
                                    <template v-slot:label>
                                    {{ $t('foreignCompany.country_name') }} <span class="text-danger">*</span>
                                    </template>
                                    <b-form-select
                                    id="country_id"
                                    plain
                                    v-model="formData.country_id"
                                    :options="countryList"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    >
                                    <template v-slot:first>
                                        <b-form-select-option :value="0" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                                    </template>
                                    </b-form-select>
                                    <div class="invalid-feedback">
                                    {{ errors[0] }}
                                    </div>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                            <ValidationProvider name="Organization Name" vid="org_name" rules="required">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="4"
                                  label-for="org_name"
                                  slot-scope="{ valid, errors }"
                                  >
                                  <template v-slot:label>
                                  {{ $t('foreignCompany.organization_name')}} <span class="text-danger">*</span>
                                  </template>
                                  <b-form-input
                                  type="text"
                                  id="org_name"
                                  v-model="formData.org_name"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  ></b-form-input>
                                  <div class="invalid-feedback">
                                  {{ errors[0] }}
                                  </div>
                              </b-form-group>
                            </ValidationProvider>
                        </b-col>
                         <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                            <ValidationProvider name="Address" vid="address">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="4"
                                  label-for="address"
                                  slot-scope="{ valid, errors }"
                                  >
                                  <template v-slot:label>
                                  {{ $t('foreignCompany.address')}}
                                  </template>
                                  <b-form-input
                                    type="text"
                                    id="address"
                                    v-model="formData.address"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                  ></b-form-input>
                                  <div class="invalid-feedback">
                                  {{ errors[0] }}
                                  </div>
                              </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                            <ValidationProvider name="Phone No" vid="phone_no">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="4"
                                  label-for="phone_no"
                                  slot-scope="{ valid, errors }"
                                  >
                                  <template v-slot:label>
                                  {{ $t('foreignCompany.phone_no')}}
                                  </template>
                                  <b-form-input
                                    type="text"
                                    id="phone_no"
                                    oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                                    v-model="formData.phone_no"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                  ></b-form-input>
                                  <div class="invalid-feedback">
                                  {{ errors[0] }}
                                  </div>
                              </b-form-group>
                            </ValidationProvider>
                        </b-col>
                         <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                            <ValidationProvider name="Email" vid="email">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="4"
                                  label-for="email"
                                  slot-scope="{ valid, errors }"
                                  >
                                  <template v-slot:label>
                                    {{ $t('foreignCompany.email_address')}}
                                  </template>
                                  <b-form-input
                                    type="email"
                                    id="email"
                                    v-model="formData.email"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                  ></b-form-input>
                                  <div class="invalid-feedback">
                                  {{ errors[0] }}
                                  </div>
                              </b-form-group>
                            </ValidationProvider>
                        </b-col>
                      </b-row>
                      <b-row v-for="(detail, index) in formData.details" :key="index" class="mt-3">
                        <b-col xs="12" sm="12" md="6" lg="6" xl="6" offset="2">
                            <ValidationProvider name="Product Name" vid="`product_name$(index)`">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    label-for="`product_name$(index)`"
                                    slot-scope="{ valid, errors }"
                                    >
                                    <template v-slot:label>
                                      {{ $t('foreignCompany.product_name') }}
                                    </template>
                                    <b-form-input
                                    id="product_name"
                                    plain
                                    v-model="detail.product_name"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    >
                                    </b-form-input>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col lg="2" sm="2" md="2">
                          <b-button v-if="index === 0" type="button" @click="add()" variant="primary" class="mr-2 btn-sm">{{ $t('globalTrans.add') }}</b-button>
                          <b-button v-if="index !== 0" type="button" @click="remove(index)" variant="danger" class="mr-2 btn-sm">X</b-button>
                        </b-col>
                      </b-row>
                      <b-row>
                          <b-col xs="12" sm="12" md="12" lg="12" xl="12" class="text-right">
                              <b-button type="submit" variant="primary" class="mr-2">{{ saveBtnName }}</b-button>
                              &nbsp;
                              <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-4')">{{ $t('globalTrans.cancel') }}</b-button>
                          </b-col>
                      </b-row>
                    </b-form>
                  </ValidationObserver>
                </b-col>
              </b-row>
            </b-overlay>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { licenseRegistrationServiceBaseUrl } from '@/config/api_config'
import { foreignCompanyStore, foreignCompanyUpdate } from '../../api/routes'
import flatpickr from 'flatpickr'

export default {
  props: ['id'],
  components: {
    ValidationObserver,
    ValidationProvider
  },
  created () {
    if (this.id) {
      const tmp = this.getforeignCompany()
      this.formData = tmp
    }
  },
  mounted () {
    core.index()
    flatpickr('#datepicker', {})
  },
  data () {
    return {
      loading: false,
      totalPost: 0,
      saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
      formData: {
        country_id: 0,
        org_name: '',
        address: '',
        phone_no: '',
        email: '',
        details: [
          {
            product_name: ''
          }
        ]
      }
    }
  },
  computed: {
    countryList: function () {
     const list = this.$store.state.commonObj.countryList.filter(item => item.status === 0)
        return list.map(item => {
            if (this.$i18n.locale === 'bn') {
              return { value: item.value, text: item.text_bn }
            } else {
                return { value: item.value, text: item.text_en }
          }
        })
    }
  },
  watch: {
  },
  methods: {
    getforeignCompany () {
      const tmpData = this.$store.state.list.find(item => item.id === this.id)
      return JSON.parse(JSON.stringify(tmpData))
    },
    async saveData () {
      this.loading = true
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      let result = null
      const loadingState = { loading: false, listReload: false }

      if (this.id) {
        result = await RestApi.putData(licenseRegistrationServiceBaseUrl, `${foreignCompanyUpdate}/${this.id}`, this.formData)
      } else {
        result = await RestApi.postData(licenseRegistrationServiceBaseUrl, foreignCompanyStore, this.formData)
      }

      loadingState.listReload = true
      this.$store.dispatch('mutateCommonProperties', loadingState)
      this.loading = false

      if (result.success) {
        this.$store.commit('incentiveGrant/mutateIncentiveGrantServiceProperties', { hasDropdownLoaded: false })
        this.$toast.success({
          title: this.$t('globalTrans.success'),
          message: this.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
          color: '#D6E09B'
        })

        this.$bvModal.hide('modal-4')
      } else {
        this.$refs.form.setErrors(result.errors)
      }
    },
    add () {
      const tmp = {
        product_name: ''
      }
      this.formData.details.push(tmp)
    },
    remove (index) {
      this.formData.details.splice(index, 1)
    }
  }
}
</script>
