<template>
  <b-container fluid>
      <b-row>
        <b-col lg="12" sm="12">
          <iq-card>
            <template>
                <b-row>
                  <b-col lg="12" sm="12">
                    <b-row>
                     <b-col lg="12" sm="12">
                        <div>
                          <b-col lg="12" sm="12" class="text-center">
                              <b-table-simple striped bordered small class="mt-3">
                                  <b-tr>
                                    <b-th>{{ $t('globalTrans.org_name') }}</b-th>
                                    <b-td>{{ formData.org_name }}</b-td>
                                    <b-th>{{ $t('foreignCompany.phone_no') }}</b-th>
                                    <b-td><slot v-if="formData.phone_no">{{ ($i18n.locale === 'bn' ? '০' : '0') }}{{ $n(formData.phone_no, { useGrouping:false })}}</slot></b-td>
                                  </b-tr>
                              </b-table-simple>
                            </b-col>
                        </div>
                            <div>
                          <b-col lg="12" sm="12" class="text-center">
                            <b-table-simple striped bordered small class="mt-3">
                                <b-tr>
                                  <b-th>{{ $t('foreignCompany.email_address') }}</b-th>
                                  <b-td>{{ (formData.email)}}</b-td>
                                  <b-th>{{ $t('foreignCompany.address') }}</b-th>
                                  <b-td>{{ (formData.address)}}</b-td>
                                </b-tr>
                            </b-table-simple>
                            </b-col>
                        </div>
                        <b-overlay :show="loading">
                          <div>
                            <b-col lg="12" sm="12" class="text-center">
                                <b-table-simple striped bordered small class="mt-2">
                                    <b-tr>
                                        <b-th>{{ $t('globalTrans.sl_no') }}</b-th>
                                        <b-th>{{ $t('foreignCompany.product_name') }}</b-th>
                                    </b-tr>
                                    <b-tr v-for="(info,index) in productData" :key="index">
                                        <b-td>{{ $n(index+1) }}</b-td>
                                        <b-td>{{ (info.product_name) }}</b-td>
                                    </b-tr>
                                </b-table-simple>
                            </b-col>
                          </div>
                        </b-overlay>
                      </b-col>
                    </b-row>
                  </b-col>
              </b-row>
            </template>
          </iq-card>
        </b-col>
      </b-row>
    </b-container>
</template>
<script>
export default {
  name: 'FormLayout',
  props: ['item'],
  created () {
    this.formData = this.item
    this.productData = this.item.details
  },
  data () {
    return {
      formData: [],
      slOffset: 1,
      loading: false
    }
  },
  computed: {
  },
  methods: {
      getRelatinalData (data) {
        this.loading = true
        const countryList = this.$store.state.externalLrcpn.commonObjCommonConfig.countryList.filter(item => item.status === 0)
        const listData = data.map(item => {
          const countryObj = countryList.find(country => country.value === item.country_id)
          const generateData = {
            country_name: countryObj !== undefined ? countryObj.text_en : '',
            country_name_bn: countryObj !== undefined ? countryObj.text_bn : ''
          }
          return Object.assign({}, item, generateData)
        })
        this.loading = false
        return listData
      }
  }
}
</script>
<style>
  .hidden_header {
    display: none
  }
  .card-border {
    border: 1px solid #b9bdc1;
    box-shadow: 1px 1px 6px -1px grey;
    background-color: #dee2e6;
  }
  [dir=ltr][mode=light] .card-body {
    flex: 1 1 auto;
    min-height: 1px;
    padding: 10px;
  }
  .report-name{
    font-weight: bold !important;
    text-transform: uppercase;
  }
  .my-btn{
    padding: 2px !important;
  }
</style>
